<template>
    <div>
        <ts-page-title :title="$t('serviceTicket.pageTitle')" />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="d-flex justify-content-between">
                    <Button type="info" ghost @click="addNew">
                        {{ $t('addNew') }}
                    </Button>
                    <div class="tw-justify-end tw-space-x-3 tw-flex">
                        <div class="row tw-w-80">
                            <label
                                class="tw-text-xs col-md-4 tw-whitespace-nowrap tw-py-2 tw-text-right"
                                >{{ $t('serviceTicket.ticketStatus') }} :
                            </label>
                            <div class="col-md-8">
                                <Select
                                    class="tw-w-60"
                                    v-model="ticket_status"
                                    multiple
                                    :max-tag-count="1"
                                    :placeholder="$t('all')"
                                    @on-change="fetchData"
                                >
                                    <Option
                                        v-for="ticketStatus in ticketStatusList"
                                        :value="ticketStatus.ticket_status_id"
                                        :key="ticketStatus.ticket_status_id"
                                        :label="ticketStatus.ticket_status"
                                    >
                                        {{ ticketStatus.ticket_status }}
                                    </Option>
                                </Select>
                            </div>
                        </div>
                        <Input
                            search
                            v-model="search"
                            :placeholder="$t('serviceTicket.search')"
                            :style="width"
                            @on-focus="() => (width = 'width: 500px')"
                            @on-blur="() => (width = 'width: 300px')"
                        />
                        <ts-button
                            v-tooltip="$t('filter')"
                            @click.prevent="visible = true"
                        >
                            <i class="fa fa-filter"></i>
                        </ts-button>
                    </div>
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template slot-scope="{ row }" slot="branch">
                    {{ row.branch ? row.branch.branch_name_en : '' }}
                </template>
                <template slot-scope="{ row }" slot="customer_name">
                    {{ row.customer ? row.customer.customer_name : '' }}
                </template>
                <template slot-scope="{ row }" slot="contact_number">
                    {{ row.customer ? row.customer.contact_number : '' }}
                </template>
                <template slot-scope="{ row }" slot="ticket_status">
                    <span
                        class="badge"
                        :class="ticketStatusColor(row.ticket_status)"
                        >{{
                            row.service_ticket_status
                                ? row.service_ticket_status.ticket_status
                                : ''
                        }}</span
                    >
                </template>
                <template slot-scope="{ row }" slot="served_by">
                    {{
                        row.served_by_name
                            ? row.served_by_name.employee
                                ? row.served_by_name.employee.employee_name_en
                                : ''
                            : ''
                    }}
                </template>
                <template slot-scope="{ row }" slot="ticket_owner">
                    {{
                        row.ticket_owner_name
                            ? row.ticket_owner_name.employee_name_en
                            : ''
                    }}
                </template>
                <template slot-scope="{ row }" slot="service_quotation_count">
                    <span
                        class="badge bg-success"
                        v-if="row.service_quotation_count > 0"
                        >Yes</span
                    >
                    <span class="badge bg-danger" v-else>No</span>
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a
                        v-if="isUpdateTicketStatus(row)"
                        href="#"
                        class="text-primary"
                        @click.prevent="onAssignTicketOwner(row)"
                    >
                        <Icon
                            v-tooltip="$t('serviceTicket.assignTicketOwner')"
                            v-if="row.ticket_status == 1"
                            type="md-person-add"
                            size="20"
                        />
                        <Icon
                            v-tooltip="$t('serviceTicket.response')"
                            v-if="row.ticket_status == 3"
                            type="md-chatboxes"
                            size="20"
                        />
                        <Icon
                            v-tooltip="$t('serviceTicket.done')"
                            v-if="row.ticket_status == 6"
                            type="md-checkbox-outline"
                            size="20"
                        />
                    </a>
                    <a
                        v-if="row.ticket_status == 1"
                        href="#"
                        class="text-primary ml-2"
                        @click.prevent="edit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                    <Poptip
                        v-if="row.ticket_status == 1"
                        confirm
                        :title="$t('areYouSure')"
                        @on-ok="deleteRecord(row)"
                        :transfer="true"
                        :ok-text="$t('yes')"
                        :cancel-text="$t('no')"
                    >
                        <a
                            class="ml-2 text-danger"
                            v-tooltip="$t('delete')"
                            :disabled="row._deleting"
                        >
                            <i
                                class="fas fa-circle-notch fa-spin"
                                v-if="row._deleting"
                            ></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                    </Poptip>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
        </ts-panel>

        <!-- form-action -->
        <Modal
            v-model="showFormUpdateTicketStatus"
            draggable
            scrollable
            :mask="false"
            footer-hide
            width="500px"
            :title="$t('serviceTicket.ticketStatus')"
            centered
            :zIndex="1020"
            :closable="false"
        >
            <div class="tw-space-y-2">
                <div class="row">
                    <div class="col-md-12">
                        <label class="tw-text-xs required">{{
                            $t('serviceTicket.ticketStatus')
                        }}</label>
                        <Select
                            v-model="model.ticket_status"
                            class="col-md-12"
                            @on-change="onChangeTicketStatus()"
                        >
                            <Option
                                v-for="ticketStatus in getTicketStatusList(
                                    model.ticket_status
                                )"
                                :value="ticketStatus.ticket_status_id"
                                :key="ticketStatus.ticket_status_id"
                                >{{ ticketStatus.ticket_status }}
                            </Option>
                        </Select>
                        <div
                            class="invalid-feedback tw-text-xs"
                            v-if="errors.has('location_name_kh')"
                        >
                            {{ errors.first('location_name_kh') }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label
                            class="tw-text-xs"
                            :class="model.ticket_status != 1 ? 'required' : ''"
                            >{{ $t('serviceTicket.ticketOwner') }}</label
                        >
                        <Select
                            class="col-md-12"
                            v-model.number="model.ticket_owner"
                            :filter-by-label="true"
                            :placeholder="$t('select')"
                            :clearable="true"
                            filterable
                            :disabled="model.ticket_status != 2"
                            @on-query-change="handleSearchEmployee"
                        >
                            <Option
                                v-for="employee in employeeList"
                                :value="employee.employee_id"
                                :key="employee.employee_id"
                                :label="employee.employee_name_en"
                            >
                                {{ employee.employee_name_en }}
                            </Option>
                        </Select>
                        <div class="validate" v-if="errors.has('ticket_owner')">
                            {{ errors.first('ticket_owner') }}
                        </div>
                    </div>
                </div>
                <div class="row" v-if="model.ticket_status == 7">
                    <div class="col-md-12">
                        <label class="required tw-text-xs">{{
                            $t('repairProductProfile.completionDate')
                        }}</label>
                        <DatePicker
                            :value="model.completion_date"
                            type="datetime"
                            style="width: 100%"
                            placeholder="DD-MM-YYYY HH:mm:ss"
                            format="dd-MM-yyyy HH:mm:ss"
                            @on-change="onChangeCompletionDate"
                        ></DatePicker>
                        <div
                            class="text-danger"
                            v-if="errors.has('completion_date')"
                        >
                            {{ errors.first('completion_date') }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 tw-mt-3 tw-text-right">
                        <ts-button
                            class="tw-mr-2"
                            @click.prevent="
                                () => (showFormUpdateTicketStatus = false)
                            "
                        >
                            {{ $t('cancel') }}
                        </ts-button>
                        <ts-button
                            color="primary"
                            @click.prevent="onUpdateTicketStatus"
                            :waiting="waiting"
                            >{{ $t('update') }}</ts-button
                        >
                    </div>
                </div>
            </div>
        </Modal>

        <Drawer
            :title="$t('filter')"
            :closable="false"
            :value="visible"
            @on-close="() => (visible = false)"
            width="300px"
        >
            <div class="row tw-mb-3">
                <div class="col-md-12">
                    <label class="">{{ $t('serviceTicket.branch') }}</label>
                    <Select
                        v-model="branch_id"
                        class="tw-w-full"
                        filterable
                        multiple
                        :max-tag-count="1"
                    >
                        <Option
                            v-for="branch in branchs"
                            :value="branch.value"
                            :key="branch.value"
                        >
                            {{ branch.label }}
                        </Option>
                    </Select>
                </div>
            </div>
        </Drawer>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { debounce } from 'lodash'
import { mapActions, mapGetters, mapState } from 'vuex'
import expandRow from './table-expand.vue'
import moment from 'moment'

export default {
    name: 'serviceTicket',
    data () {
        return {
            loading: false,
            employee_loading: false,
            waiting: false,
            showForm: false,
            showFormUpdateTicketStatus: false,
            errors: new Errors(),
            branchs: [],
            model: {
                service_ticket_id: null,
                ticket_status: null,
                ticket_owner: null,
                completion_date: null
            },
            width: 'width: 300px',
            visible: false
        }
    },
    created () {
        this.fetchFilterData()
    },
    computed: {
        ...mapState('customerService/serviceTicket', [
            'resources',
            'pagination',
            'formViewDatas'
        ]),
        ...mapGetters('customerService/serviceTicket', ['getTicketStatusList']),
        search: {
            get () {
                return this.$store.state.customerService.serviceTicket.search
            },
            set (newValue) {
                this.$store.commit(
                    'customerService/serviceTicket/SET_SEARCH',
                    newValue
                )
                this.$store.commit(
                    'customerService/serviceTicket/RESET_CURRENT_PAGE'
                )
            }
        },
        columns () {
            return [
                {
                    type: 'expand',
                    render: (h, params) => {
                        return h(expandRow, {
                            props: {
                                row: params.row
                            }
                        })
                    },
                    align: 'center',
                    width: 50
                },
                {
                    title: this.$t('serviceTicket.branch'),
                    slot: 'branch',
                    minWidth: 120,
                    sortable: true
                },
                {
                    title: this.$t('serviceTicket.ticketNo'),
                    minWidth: 140,
                    key: 'ticket_no'
                },
                {
                    title: this.$t('serviceTicket.serviceDate'),
                    key: 'service_date',
                    minWidth: 180,
                    sortable: true
                },
                {
                    title: this.$t('serviceTicket.expectedCompletionDate'),
                    key: 'expected_completion_date',
                    minWidth: 220,
                    sortable: true
                },
                {
                    title: this.$t('serviceTicket.customer'),
                    slot: 'customer_name',
                    minWidth: 150,
                    sortable: true
                },
                {
                    title: this.$t('serviceTicket.customerPhone'),
                    slot: 'contact_number',
                    minWidth: 160,
                    sortable: true
                },
                {
                    title: this.$t('serviceTicket.servedBy'),
                    slot: 'served_by',
                    minWidth: 130,
                    sortable: true
                },
                {
                    title: this.$t('serviceTicket.ticketStatus'),
                    slot: 'ticket_status',
                    align: 'center',
                    minWidth: 130
                },
                {
                    title: this.$t('serviceTicket.ticketOwner'),
                    slot: 'ticket_owner',
                    align: 'center',
                    minWidth: 150
                },
                {
                    title: this.$t('serviceTicket.completionDate'),
                    key: 'completion_date',
                    align: 'center',
                    minWidth: 180
                },
                {
                    title: this.$t('serviceTicket.quotation'),
                    slot: 'service_quotation_count',
                    align: 'center',
                    minWidth: 100
                },
                {
                    title: this.$t('serviceTicket.amount'),
                    key: 'grand_total',
                    align: 'center',
                    minWidth: 100
                },
                {
                    title: this.$t('serviceTicket.remarks'),
                    key: 'remarks',
                    minWidth: 100
                },
                {
                    title: this.$t('serviceTicket.createdDate'),
                    key: 'created_date',
                    minWidth: 180
                },
                {
                    title: this.$t('actions'),
                    key: 'action',
                    slot: 'action',
                    fixed: 'right',
                    align: 'center',
                    minWidth: 100
                }
            ]
        },
        branch_id: {
            get () {
                return this.$store.state.customerService.serviceTicket.branch_id
            },
            set (newValue) {
                this.$store.commit(
                    'customerService/serviceTicket/SET_BRANCH_ID',
                    newValue
                )
            }
        },

        employeeList () {
            return this.formViewDatas.employee
        },
        ticketStatusList () {
            return this.formViewDatas.serviceTicketStatus
        },
        ticket_status: {
            get () {
                return this.$store.state.customerService.serviceTicket
                    .ticket_status
            },
            set (newValue) {
                this.$store.commit(
                    'customerService/serviceTicket/SET_TICKET_STATUS',
                    newValue
                )
            }
        }
    },
    methods: {
        ...mapActions('customerService/serviceTicket', ['getFormViewModel']),
        ticketStatusColor (value) {
            switch (value) {
                case 1:
                    return 'bg-secondary'
                case 2:
                    return 'bg-warning'
                case 3:
                    return 'bg-success'
                case 4:
                    return 'bg-green'
                case 5:
                    return 'bg-danger'
                case 6:
                    return 'bg-info'
                case 7:
                    return 'tw-bg-green-600'
                default:
                    return 'bg-secondary'
            }
        },
        onChangeCompletionDate (value) {
            this.model.completion_date = value
        },
        isUpdateTicketStatus (row) {
            if (row.ticket_status == 3 && row.service_quotation_count <= 0) {
                return false
            } else if ([1, 3, 6].includes(row.ticket_status)) {
                return true
            }
            return false
        },
        handleSearchEmployee: debounce(function (value) {
            this.employee_loading = true
            this.getFormViewModel({
                index: 'employee',
                params: {
                    fnName: 'employee',
                    search: value
                }
            }).finally(() => (this.employee_loading = false))
        }, 800),
        onChangeTicketStatus (value) {
            if (value == 1) {
                this.model.ticket_owner = null
            }
        },
        onAssignTicketOwner (value) {
            let ticket_status = value.ticket_status

            switch (value.ticket_status) {
                case 1:
                    ticket_status = 2
                    break
                case 3:
                    ticket_status = 3
                    break
                case 6:
                    ticket_status = 7
                    break
                default:
                    ticket_status = value.ticket_status
                    break
            }

            this.model.service_ticket_id = value.service_ticket_id
            this.model.ticket_status = ticket_status
            this.model.ticket_owner = value.ticket_owner
            this.model.completion_date = value.completion_date
                ? moment(value.completion_date, 'DD-MM-YYYY hh:mm:ss a').format(
                      'DD-MM-YYYY HH:mm:ss'
                  )
                : null
            this.getFormViewModel({
                index: 'employee',
                params: {
                    fnName: 'employee'
                }
            })
            this.showFormUpdateTicketStatus = true
        },
        fetchFilterData () {
            this.getFormViewModel({
                index: 'serviceTicketStatus',
                params: {
                    fnName: 'serviceTicketStatus'
                }
            })
            this.loading = true
            this.$store
                .dispatch('auth/user/getBranchAssignedForFilter')
                .then(response => {
                    this.branchs = response.data.map(el => ({
                        value: el.branch_id,
                        label: el.branch_name_en
                    }))
                    this.branch_id = [
                        this.$store.state.authUser.user.default_branch.branch_id
                    ]
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },

        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('customerService/serviceTicket/fetch', {
                    ...attributes
                })
                .then(() => {
                    this.loading = false
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
        },
        addNew () {
            this.$router.push({ name: 'service-ticket-create' })
        },
        edit (record) {
            this.$store.commit(
                'customerService/serviceTicket/SET_EDIT_DATA',
                record
            )
            this.$router.push({
                name: 'service-ticket-edit',
                params: { id: record.service_ticket_id }
            })
        },
        clearEdit () {
            this.$refs.form_action.clearInput()
            this.$store.commit(
                'customerService/serviceTicket/SET_EDIT_DATA',
                {}
            )
            this.showForm = false
        },
        onUpdateTicketStatus () {
            this.waiting = true
            this.$store
                .dispatch('customerService/serviceTicket/updateTicketStatus', {
                    id: this.model.service_ticket_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                    this.fetchData()
                    this.waiting = false
                    this.showFormUpdateTicketStatus = false
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                    this.waiting = false
                })
        },
        deleteRecord (record) {
            record._deleting = true
            this.$store
                .dispatch(
                    'customerService/serviceTicket/destroy',
                    record.service_ticket_id
                )
                .then(response => {
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                    this.fetchData()
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    record._deleting = false
                })
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'SERVICE TICKET',
                desc: not.text
            })
        }
    },
    watch: {
        search: debounce(function () {
            this.fetchData()
        }, 500),
        branch_id: debounce(function () {
            this.fetchData(this.search)
        }, 500)
    }
    // beforeRouteEnter (to, from, next) {
    //     next(vm => {
    //         vm.fetchData()
    //     })
    // }
}
</script>
